exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acceder-js": () => import("./../../../src/pages/acceder.js" /* webpackChunkName: "component---src-pages-acceder-js" */),
  "component---src-pages-condiciones-js": () => import("./../../../src/pages/condiciones.js" /* webpackChunkName: "component---src-pages-condiciones-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-prueba-final-js": () => import("./../../../src/pages/prueba-final.js" /* webpackChunkName: "component---src-pages-prueba-final-js" */),
  "component---src-pages-regalo-js": () => import("./../../../src/pages/regalo.js" /* webpackChunkName: "component---src-pages-regalo-js" */),
  "component---src-pages-taza-1-acertijo-bate-js": () => import("./../../../src/pages/taza1/acertijo/bate.js" /* webpackChunkName: "component---src-pages-taza-1-acertijo-bate-js" */),
  "component---src-pages-taza-1-acertijo-borracho-js": () => import("./../../../src/pages/taza1/acertijo/borracho.js" /* webpackChunkName: "component---src-pages-taza-1-acertijo-borracho-js" */),
  "component---src-pages-taza-1-acertijo-suma-js": () => import("./../../../src/pages/taza1/acertijo/suma.js" /* webpackChunkName: "component---src-pages-taza-1-acertijo-suma-js" */),
  "component---src-pages-taza-1-fin-js": () => import("./../../../src/pages/taza1/fin.js" /* webpackChunkName: "component---src-pages-taza-1-fin-js" */),
  "component---src-pages-taza-1-index-js": () => import("./../../../src/pages/taza1/index.js" /* webpackChunkName: "component---src-pages-taza-1-index-js" */),
  "component---src-pages-taza-2-acertijo-taza-js": () => import("./../../../src/pages/taza2/acertijo/taza.js" /* webpackChunkName: "component---src-pages-taza-2-acertijo-taza-js" */),
  "component---src-pages-taza-2-compatibilidad-index-js": () => import("./../../../src/pages/taza2/compatibilidad/index.js" /* webpackChunkName: "component---src-pages-taza-2-compatibilidad-index-js" */),
  "component---src-pages-taza-2-fin-js": () => import("./../../../src/pages/taza2/fin.js" /* webpackChunkName: "component---src-pages-taza-2-fin-js" */),
  "component---src-pages-taza-2-index-js": () => import("./../../../src/pages/taza2/index.js" /* webpackChunkName: "component---src-pages-taza-2-index-js" */)
}

